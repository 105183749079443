export const hexReg = /^#([0-9a-f]{3}){1,2}$/i

/**
 * Indicates if given value is a valid color value in rgb color model
 * @param {number} val - number representing color value from the range 0-255
 * @returns {boolean} true for valid value, otherwise false
 */
const isValidColorValue = (val: number): boolean => {
  if (isNaN(val)) {
    return false
  }
  if (val < 0 || val > 255) {
    return false
  }
  return true
}

/**
 * Converts color in hex model to rgba
 * {@link https://gist.github.com/danieliser/b4b24c9f772066bcf0a6#file-es6-js source}
 * @param {string} hexCode - valid hex code with or without #
 * @param {number} opacity - number from 0 to 1 or 0 to 100 representing color opacity
 * @returns {string|boolean} color converted to rgba or false on failure
 */
export const hexToRgba = (
  hexCode: string,
  opacity: number = 1
): string | boolean => {
  let hex = hexCode.replace('#', '')

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  if (!isValidColorValue(r) || !isValidColorValue(g) || !isValidColorValue(b)) {
    return false
  }

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100
  }

  if (opacity < 0 || opacity > 1) {
    opacity = 1
  }

  return `rgba(${r},${g},${b},${opacity})`
}
