<template>
  <div
    class="hero-banner col-down"
    :class="[themeColorClass, bannerClass, bannerCategoriesClass]"
  >
    <UiImg
      :image="backgroundImage"
      :wrapper-index="index"
      class="hero-banner__hero-image"
      decorative
      :sizes="{
        xs: '400',
        sm: '768',
        md: '1024',
        lg: '1360',
        xl: '1600'
      }"
    />

    <div
      v-if="isGradient"
      class="hero-banner__mask"
      :style="gradientBackground"
    />

    <div
      class="hero-banner__wrap content-container-template align-center col v-center"
    >
      <div
        class="hero-banner__content col"
        :class="contentHorizontalAlign"
        :style="textColor"
      >
        <div v-if="logoImage" class="hero-banner__logo-wrap">
          <UiImg
            :image="logoImage"
            :wrapper-index="index"
            use-image-size
            decorative
            disable-placeholder
            class="hero-banner__logo"
          />
        </div>

        <UiHeader
          v-if="header"
          class="hero-banner__title"
          :class="textPosition"
          :header="header"
          :subheader="subheader"
          :header-layout="headerLayout"
          :header-position="headerPosition"
          :header-size="headerSize"
          :theme-color="themeColor"
        />

        <T3HtmlParser
          v-if="bodytext"
          :content="bodytext"
          class="hero-banner__text"
          :class="textPosition"
        />

        <ul
          v-if="Array.isArray(tagList) && tagList.length"
          class="hero-banner__categories"
          :class="{ 'hero-banner__categories--icon': tagIcon }"
        >
          <li
            v-for="tag in tagList"
            :key="tag.id"
            class="hero-banner__category"
          >
            <span />
            {{ tag.name }}
          </li>
        </ul>

        <ButtonBase :button="button" class="hero-banner__button" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { UiCeCaseStudyBannerProps } from '~/types'
import { ButtonBase, UiHeader, UiImg } from '~ui/components'
import useTheming from '~ui/composables/useTheming'
import { hexToRgba } from '~ui/helpers/hexToRgba'

const props = defineProps<UiCeCaseStudyBannerProps>()

const backgroundImage = computed(() => props.image?.[0] || '')
const logoImage = computed(() => props.logo?.[0] || '')

const themeColorClass = useTheming(props.themeColor)
const textPosition = computed(() => `text-${props.headerPosition}`)
const textColor = computed(() => ({ color: props.bannerTextColor }))

const contentHorizontalAlign = computed(() => {
  if (props.headerPosition === 'center') return 'h-center'
  if (props.headerPosition === 'right') return 'h-end'

  return 'h-start'
})

const gradientBackground = computed(() => {
  const startColor = hexToRgba(props.gradientColor, props.gradientOpacity / 100)

  if (!startColor) return ''

  return {
    background: `linear-gradient(to ${props.gradientDirection}, ${startColor}, rgba(255, 255, 255, 0))`
  }
})

const bannerClass = computed(() =>
  props.appearance?.height && props.appearance.height !== 0
    ? 'hero-banner--dynamic-height'
    : ''
)

const bannerCategoriesClass = computed(() =>
  Array.isArray(props.tagList) && props.tagList.length
    ? 'hero-banner--with-categories'
    : ''
)
</script>

<style lang="scss">
.hero-banner {
  min-height: rem(340px);
  justify-content: center;
  position: relative;
  padding: rem(60px) 0 rem(40px);

  @include media-query(sm) {
    &:not(.hero-banner--dynamic-height) {
      min-height: rem(420px);
      height: vw(rem(550px), rem(1440px));
      max-height: rem(550px);
      padding: 0;
    }
  }

  @include media-query(max md) {
    &--with-categories:not(.hero-banner--dynamic-height) {
      height: auto;
      max-height: none;
      padding: max(4vw, rem(40px)) 0;
    }
  }

  &--dynamic-height {
    min-height: 0;
    height: auto;
    max-height: none;
    padding: 4vw 0;
    background-repeat: repeat-y;
    background-size: 100% auto;

    @include media-query(lg) {
      padding: rem(57px) 0;
    }
  }

  &__hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include media-query(xl) {
      object-position: center 30%;
    }
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__wrap {
    height: 100%;
    padding: 0 em(15px);
  }

  &__content {
    position: relative;
    z-index: z-index(base);

    @include media-query(max sm) {
      & br {
        display: none;
      }
    }
  }

  &__logo-wrap {
    margin-bottom: rem(30px);
    max-width: rem(180px);
    max-height: rem(55px);

    @include media-query(sm) {
      margin-bottom: rem(25px);
      max-width: rem(220px);
      max-height: rem(100px);
    }

    @include media-query(lg) {
      max-width: 100%;
    }
  }

  &__logo {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__title.ui-header > * {
    margin-bottom: 0;
  }

  &__text {
    font-size: rem(18px);
    padding-top: rem(28px);

    & p:last-child {
      margin-bottom: 0;
    }
  }

  &__button {
    margin-top: rem(30px);
  }

  &__categories {
    margin: rem(26px) 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: rem(16px);
    justify-content: center;
    flex-wrap: wrap;
  }

  &__category {
    padding: em(3px) em(6px);
    border: 2px solid hsl(0 0% 100% / 70%);
    background-color: hsl(0 0% 0% / 5%);
    border-radius: em(2px);
    font-weight: 600;
    white-space: nowrap;
  }

  &__categories--icon {
    @include media-query(md) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__categories--icon &__category {
    display: flex;
    align-items: center;
    gap: rem(16px);
    padding: em(12px);
    border: 2px solid hsl(0 0% 100% / 32%);
    background-color: hsl(0 0% 100% / 12%);
    border-radius: em(4px);
    font-weight: 700;
    white-space: initial;

    & span {
      height: rem(48px);
      width: rem(48px);
      display: inline-block;
      flex-shrink: 0;
      background-color: hsl(0 0% 100% / 32%);
      border-radius: em(2px);

      &::before {
        content: '';
        height: 100%;
        width: 100%;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 70%;
        background-image: url('~ui/assets/icons/icon-check.svg');
        filter: brightness(0) invert(1);
        scale: 1.25;
      }
    }

    @include media-query(max md) {
      gap: rem(12px);
      padding: em(10px);

      & span {
        height: rem(40px);
        width: rem(40px);

        &::before {
          scale: 1;
        }
      }
    }
  }
}
</style>
